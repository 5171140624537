import 'jquery';

const handleControlClick = e => {
    e.preventDefault();

    const id = jQuery(e.currentTarget).attr('data-input-id');
    const value = jQuery(e.currentTarget).attr('data-value');
    const el = jQuery(`[data-input-id=${id}]`);

    jQuery(e.currentTarget).toggleClass('-active');
    console.dir(el);

    if (el.length > 0 && jQuery(e.currentTarget).hasClass('-active')) {
        el.val(value);
    }
}

jQuery(document).ready(function () {
    jQuery(".proxy-inputs-list .button").click(handleControlClick);
});