import 'jquery';
import 'slick-carousel';
import { getArrowIcon, SLICK_CONF } from '../slick-common/slick-common';

jQuery(document).ready(function () {
    jQuery('.gallery-list.-isCarousel').each(function () {
        let slickConf = {
            ...SLICK_CONF,
            arrows: true,
            dots: false,
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            controls: true,
            autoplay: true,
            autoplaySpeed: 4000,
        };

        jQuery(this).not('.slick-initialized').slick(slickConf);
    });
});