import 'jquery';
import 'slick-carousel';
import { getArrowIcon, SLICK_CONF } from '../slick-common/slick-common';

jQuery(document).ready( function() {
    jQuery('.partnersList.-isCarousel').each( function() {
        let slickConf = {...SLICK_CONF};
        slickConf.arrows = false
        slickConf.slidesToShow = 5;

        jQuery(this).slick(slickConf);
    });
});