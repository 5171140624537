// Helper methods and config for slick-carousels
const getArrowIcon = ({ arrowType }) => {
    return '<svg class="icon svg-canvas" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 20 32" xml:space="preserve">\n' +
        '<use xlink:href="#' + (arrowType == 'prev' ? 'chevron-left' : 'chevron-right') + '"></use>\n' +
        '</svg>';
};

const SLICK_CONF = {
    accessibility: true,
    adaptiveHeight: false,
    arrows:true,
    asNavFor: null,
    prevArrow: '<button type="button" class="slick-prev">' + getArrowIcon({ arrowType: 'prev' }) + '</button>',
    nextArrow: '<button type="button" class="slick-next">' + getArrowIcon({ arrowType: 'next' }) + '</button>',
    autoplay: false,
    autoplaySpeed: 3000,
    centerMode: false,
    centerPadding: '50px',
    cssEase: 'ease',
    customPaging: null,
    dots: false,
    dotsClass: 'slick-dots',
    draggable: true,
    easing: 'linear',
    edgeFriction: 0.35,
    fade: false,
    focusOnSelect: false,
    focusOnChange: false,
    infinite: true,
    initialSlide: 0,
    lazyLoad: 'ondemand',
    mobileFirst: false,
    pauseOnHover: true,
    pauseOnFocus: true,
    pauseOnDotsHover: false,
    respondTo: 'window',
    responsive: null,
    rows: 1,
    rtl: false,
    slide: '',
    slidesPerRow: 1,
    slidesToShow: 1,
    slidesToScroll: 1,
    speed: 500,
    swipe: true,
    swipeToSlide: false,
    touchMove: true,
    touchThreshold: 5,
    useCSS: true,
    useTransform: true,
    variableWidth: false,
    vertical: false,
    verticalSwiping: false,
    waitForAnimate: true,
    zIndex: 1000
};

export { getArrowIcon, SLICK_CONF };