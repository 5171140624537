import 'jquery';
import 'slick-carousel';
import { getArrowIcon, SLICK_CONF } from '../slick-common/slick-common';

const getArrowLongIcon = ({ arrowType }) => {
    return '<svg class="icon svg-canvas" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 32 24" xml:space="preserve">\n' +
        '<use xlink:href="#' + (arrowType == 'prev' ? 'arrow-rounded-l' : 'arrow-rounded-r') + '"></use>\n' +
        '</svg>';
};

$(document).ready( function() {
    $('.content-listings-carousel').each( function() {
        let slickConf = {
            ...SLICK_CONF,
            dots: false,
            infinite: true,
            margin: 20,
            slidesToShow: 3,
            arrows: true,
            slidesToScroll: 1,
            controls: true,
            autoplay: true,
            autoplaySpeed: 4000,
            responsive: [
                {
                    breakpoint: 980,
                    settings: {
                        slidesToShow: 2,
                        arrows: true,

                    }
                },
                {
                    breakpoint: 480,

                    settings: {
                        slidesToShow: 1,
                        arrows: true,
                    }
                }
            ]
        };

        if ($(this).hasClass('-relatedEvents')) {
            slickConf.prevArrow = '<button type="button" class="slick-prev">' + getArrowLongIcon({ arrowType: 'prev' }) + '</button>';
            slickConf.nextArrow = '<button type="button" class="slick-next">' + getArrowLongIcon({ arrowType: 'next' }) + '</button>';
        }

        $(this).slick(slickConf);
    });
});