import 'jquery';
import 'slick-carousel';
import { SLICK_CONF } from '../slick-common/slick-common';

const handleControlClick = e => {
    e.preventDefault();

    let el = jQuery(e.currentTarget);
    let componentId = jQuery(e.currentTarget).attr('data-attr-component-id');
    let id = jQuery(e.currentTarget).attr('data-attr-id');
    let contentEl = jQuery(`#tabContent-${id}`);

    if(contentEl.length > 0) {
        $(`#tabControlsList-${componentId} .-active`).removeClass('-active');
        $(`#tabContentList-${componentId} .-active`).removeClass('-active');

        $(el).parent().addClass('-active');
        $(el).addClass('-active');
        contentEl.addClass('-active');

        const sliders = contentEl.find('.slick-slider');
        if (sliders.length > 0) {
            $(sliders).each( function() {
                $(this).slick('setPosition');
            });
        }
    }
};

jQuery(document).ready( function() {
    $('.tabControlsList .item:first-child').addClass('-active');
    $('.tabContentList .item:first-child').addClass('-active');

    $('.tabControlsList .button').click(handleControlClick);

    $('.tabControlsList.-isCarousel').each( function() {
        let slickConf = {
            ...SLICK_CONF,
            arrows: false,
            slidesToShow: 3
        };

        $(this).slick(slickConf);
    });
});