import 'jquery';
import 'slick-carousel';
import { getArrowIcon, SLICK_CONF } from '../slick-common/slick-common';

jQuery(document).ready(function () {
    jQuery('.banners').each(function () {
        let slickConf = {
            ...SLICK_CONF,
            dots: true,
            infinite: true,
            slidesToShow: 1,
            arrows: true,
            slidesToScroll: 1,
            controls: true,
            autoplay: true,
            autoplaySpeed: 7000,
        };

        jQuery(this).slick(slickConf);
    });
});