import 'jquery';
import 'mmenu-js';

jQuery(document).ready(function () {
	document.addEventListener(
        "DOMContentLoaded", () => {
            const menu = new Mmenu("#main-navigation", {
            }, {

                offCanvas: {
                    clone: true
                }
            });

            const api = menu.API;
            jQuery("#mobile-menu-button").click(function (event) {
                if (!jQuery(this).hasClass("is-active")) {
                    api.open();
                    jQuery(this).addClass("is-active");
                }
            });
            api.bind("close:start", function () {
                jQuery("#mobile-menu-button").removeClass("is-active");
            });
        }
    );
});